var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-row',[_c('a-col',{attrs:{"span":20}},[_c('a-form-model',{attrs:{"colon":false,"model":_vm.form,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"策划名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"检查类型"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.qualityInspectType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"项目名称"},model:{value:(_vm.form.projectName),callback:function ($$v) {_vm.$set(_vm.form, "projectName", $$v)},expression:"form.projectName"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"状态"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l(([
                {
                  name: '未创建',
                  value: 'notCreated',
                },
                     {
                  name: '已创建',
                  value: 'created',
                },
                     {
                  name: '审批中',
                  value: 'reviewing',
                },
                     {
                  name: '已通过',
                  value: 'approved',
                },
                     {
                  name: '已驳回',
                  value: 'rejected',
                },
                {
                  name: '已完成',
                  value: 'completed',
                },
                     {
                  name: '已取消',
                  value: 'cancelled',
                },
                ]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":4}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[_c('a-space',[(_vm.$getPermission(_vm.$route.path + '/add'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/add')}}},[_vm._v("录入")]):_vm._e()],1)],1)])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowKey":"id"},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"策划名称","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"检查类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"qualityInspectType","dictValue":text.type}})]}}])}),_c('a-table-column',{attrs:{"title":"检查实施单位","data-index":"unitName"}}),_c('a-table-column',{attrs:{"title":"受检单位","data-index":"inspectUnitName"}}),_c('a-table-column',{attrs:{"title":"受检项目","data-index":"inspectProjectName"}}),_c('a-table-column',{attrs:{"title":"检查时间段","data-index":"inspectTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"进度","data-index":"progress","align":"center"}}),_c('a-table-column',{attrs:{"title":"状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',[(text.status === 'notCreated')?_c('a-badge',{attrs:{"color":"red","text":"未创建"}}):_vm._e(),(text.status === 'created')?_c('a-badge',{attrs:{"color":"red","text":"已创建"}}):_vm._e(),(text.status === 'reviewing')?_c('a-badge',{attrs:{"status":"processing","text":"审批中"}}):_vm._e(),(text.status === 'approved')?_c('a-badge',{attrs:{"color":"green","text":"已通过"}}):_vm._e(),(text.status === 'rejected')?_c('a-badge',{attrs:{"color":"red","text":"已驳回"}}):_vm._e(),(text.status === 'completed')?_c('a-badge',{attrs:{"color":"green","text":"已完成"}}):_vm._e(),(text.status === 'cancelled')?_c('a-badge',{attrs:{"color":"red","text":"已取消"}}):_vm._e()],1)]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[(_vm.$getPermission(_vm.$route.path + '/detail'))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.check(text)}}},[_vm._v("安全检查")]):_vm._e()])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }