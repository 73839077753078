<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="20">
          <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="策划名称" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select v-model="form.type" placeholder="检查类型" style="width: 150px">
                <a-select-option
                  v-for="item in qualityInspectType"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-input v-model="form.projectName" placeholder="项目名称" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select v-model="form.status" placeholder="状态" style="width: 150px">
                <a-select-option
                  v-for="item in [
                  {
                    name: '未创建',
                    value: 'notCreated',
                  },
                       {
                    name: '已创建',
                    value: 'created',
                  },
                       {
                    name: '审批中',
                    value: 'reviewing',
                  },
                       {
                    name: '已通过',
                    value: 'approved',
                  },
                       {
                    name: '已驳回',
                    value: 'rejected',
                  },
                  {
                    name: '已完成',
                    value: 'completed',
                  },
                       {
                    name: '已取消',
                    value: 'cancelled',
                  },
                  ]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="4">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <a-button
                type="primary"
                v-if="$getPermission($route.path + '/add')"
                @click="$router.push($route.path + '/add')"
              >录入</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="策划名称" data-index="name"></a-table-column>
        <a-table-column title="检查类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="qualityInspectType" :dictValue="text.type" />
          </template>
        </a-table-column>

        <a-table-column title="检查实施单位" data-index="unitName"></a-table-column>
        <a-table-column title="受检单位" data-index="inspectUnitName"></a-table-column>
        <a-table-column title="受检项目" data-index="inspectProjectName"></a-table-column>

        <a-table-column title="检查时间段" data-index="inspectTime" align="center"></a-table-column>

        <a-table-column title="进度" data-index="progress" align="center"></a-table-column>

        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <span>
              <a-badge v-if="text.status === 'notCreated'" color="red" text="未创建" />
              <a-badge v-if="text.status === 'created'" color="red" text="已创建" />
              <a-badge v-if="text.status === 'reviewing'" status="processing" text="审批中" />
              <a-badge v-if="text.status === 'approved'" color="green" text="已通过" />
              <a-badge v-if="text.status === 'rejected'" color="red" text="已驳回" />
              <a-badge v-if="text.status === 'completed'" color="green" text="已完成" />
              <a-badge v-if="text.status === 'cancelled'" color="red" text="已取消" />
            </span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="check(text)"
              >安全检查</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import { mapGetters } from "vuex";

function fetchList(data) {
  return request({
    url: "/office-service/quality/inspect/plan/list",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark],

  data() {
    return {
      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    qualityInspectType() {
      return this.findDataDict("qualityInspectType");
    }
  },

  mounted() {
    const { projectName } = this.$route.query;
    this.form = {
      projectName
    };
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.form = {};
      this.getList();
    },

    check(text) {
      this.$router.push(this.$route.path + "/detail?id=" + text.id);
    }
  }
};
</script>
